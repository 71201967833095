import { useDescope, useUser } from '@descope/react-sdk';
import { Box, Button, CircularProgress, Container, createTheme, Grid, ThemeProvider, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import tchLogo from '../assets/tch-logo-v.png';
import axios from "axios";

const ProcessPlayer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    userid,
    email
  } = location.state || {};

  useEffect(() => {
    if (email) {
      fetchUserData(email);
    } else {
      setTimeout(() => navigate('/tch/notfound'), 3000);
    }
  }, [email, navigate]);

  const fetchUserData = async () => {
    try {
      const searchparams = {
        email: email
      }
  
      const response = await axios.post(`${process.env.REACT_APP_DATASYNC_URL}/descope/search`, searchparams);
      const data = response.data;
  
      if (data) {
        const idVerified = data.customAttributes?.idVerified;
        if (!idVerified || (idVerified !== "APPROVED" && idVerified !== "SUSPECTED")) {
          const authToken = await handleAPICall(userid, email);
          if (authToken && typeof authToken === 'string') {
            setTimeout(() => window.location.replace(`https://ivs.idenfy.com/api/v2/redirect?authToken=${authToken}`), 3000);
          } else {
            throw new Error('Failed to get auth token');
          }
        } else {
          navigate(`/tch/checking?email=${encodeURIComponent(email)}`);
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      navigate('/tch/error', {
        state: {
          message: 'There was a problem retrieving your information, proceed to the front desk for assistance.'
        }
      });
    }
  }

  return (
    <>
      <div className='page'>
        <Container maxWidth="sm" sx={{paddingY: '16px'}}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={9}>
              <div className='descope-form' data-testid="descope-component">
                <div
                  style={{
                    padding: "2rem 2rem",
                    backgroundColor: "#1e1e1e",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{ width: "300px", marginBottom: "1rem" }}
                    src={tchLogo}
                    alt="TCH Logo"
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem', marginTop: '2rem' }}>
                    <CircularProgress
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[50],
                      }}
                      thickness={3}
                      size={60}
                    />
                  </Box>
                  <Typography mb={3}>
                    Your account is not verified. Please prepare your driver's license for ID verification. Redirecting...
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

const buildUrlWithParams = (baseUrl, params) => {
  const queryString = new URLSearchParams(params).toString();
  return `${baseUrl}?${queryString}`;
};

const handleAPICall = async (guid, loginid) => {
  const baseUrl = `https://auth.${process.env.REACT_APP_DOMAIN}/tch/checking`;
  const params = {
    email: encodeURIComponent(loginid),
  };

  const successurl = buildUrlWithParams(baseUrl, params);
  const errorurl = buildUrlWithParams(baseUrl, params);
  const unverifiedurl = buildUrlWithParams(baseUrl, params);

  let formData = new FormData(); 
  formData.append('GUID', guid); 
  formData.append('loginId', loginid); 
  formData.append('phone', ''); 
  formData.append('verifiedEmail', ''); 
  formData.append('verifiedPhone', '');
  formData.append('successurl', successurl);
  formData.append('errorurl', errorurl);
  formData.append('unverifiedurl', unverifiedurl);

  try {
    const response = await fetch(
      `https://account.${process.env.REACT_APP_DOMAIN}/api/profile/getverificationcodemobiletch`,
      {
        method: "POST",
        headers: {
          Accept: "*/*"
        },
        body: formData,
      }
    );
    const authToken = await response.json();
    return authToken;
  } catch (error) {
    console.error('Fetch error:', error);
    return null;
  }
}

const generateQrCode = async (playerNumber) => {
  const headers = new Headers();
  headers.append("x-rapidapi-key", process.env.REACT_APP_X_RAPIDAPI_KEY);
  headers.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "data": `%P${playerNumber}?`,
    "config": {
      "body": "square",
      "bodyColor": "#000000",
      "bgColor": "#FFFFFF",
      "eye1Color": "#000000",
      "eye2Color": "#000000",
      "eye3Color": "#000000",
      "eyeBall1Color": "#000000",
      "eyeBall2Color": "#000000",
      "eyeBall3Color": "#000000",
      "gradientColor1": "#000000",
      "gradientColor2": "#000000",
      "gradientType": "radial",
      "gradientOnEyes": false,
      "logo": "https://44736585.fs1.hubspotusercontent-na1.net/hubfs/44736585/tch_logo_qrcode.jpg",
      "logoMode": "clean"
    },
    "size": 200,
    "download": true,
    "file": "png"
  });

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: raw,
    redirect: "follow"
  };

  try {
    const response = await fetch("https://qrcode-monkey.p.rapidapi.com/qr/custom", requestOptions);
    const qrCodeJson = await response.json();
    const imageUrl = qrCodeJson?.imageUrl;
    return imageUrl;
  } catch (error) {
    console.error('QR code fetch error:', error);
    return null;
  }
};

const sendEmail = async (firstName, lastName, email, playerNumber, qrCodeUrl) => {
  try {
    const emailResponse = await axios.post(
      `${process.env.REACT_APP_BACKOFFICE_URL}/api/players/send-tch-registration-email`, 
      {
        firstName: firstName,
        lastName: lastName,
        email: email,
        playerNumber: playerNumber,
        qrCodeUrl: qrCodeUrl
      }
    );
    if (emailResponse.status !== 200) {
      throw new Error('Failed to send email');
    }
  } catch (error) {
    console.error('Email send error:', error);
  }
};

const DefaultBtn = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#D08E34',
          '&.active': {
            backgroundColor: '#D08E34',
            '&:hover': {
              backgroundColor: '#BE7E27'
            }
          },
          '&:hover': {
            color: 'white',
            backgroundColor: '#BE7E27'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.75rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});

export default ProcessPlayer;
