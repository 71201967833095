import { Descope } from "@descope/react-sdk";
import { Box, CircularProgress, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const DEFAULT_FLOW = "tchregistration";

const onSuccessHandler = (navigate) => async (e) => {
  const userid = e.detail.user.userId;
  const email = e.detail.user.email;
  const phone = e.detail.user.phone;
  const idVerified = e.detail.user.customAttributes.idVerified || ""
  const playerNumber = e.detail.user.customAttributes.playerNumber || "";
  const idDocnumber = e.detail.user.customAttributes.idDocnumber || "";

  // Search user in Poker Atlas before proceeding with the registration in PA
  try {
    const params = {
      id: userid,
      email: email,
      phone: phone,
      player_number: playerNumber,
      identification_id: idDocnumber,
      idVerified: idVerified,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_DATASYNC_URL}/pokeratlas/contact/push/update`,
      params
    );

    // Check if a match is found
    if (
      response.data.match === "phone" ||
      response.data.match === "email" ||
      response.data.match === "player_number" ||
      response.data.match === "identification_id"
    ) {
      if (response.data.hasMultiple) {
        // Redirect to contact support if multiple accounts found
        navigate("/tch/error-duplicates", {
          state: {
            match: response.data.match,
            matchValue: response.data.matchValue,
          },
        });
      } else if (response.data.verificationRequired === true) {
          const authToken = await handleAPICall(userid, email);
          if (authToken && typeof authToken === 'string') {
            setTimeout(() => window.location.replace(`https://ivs.idenfy.com/api/v2/redirect?authToken=${authToken}`), 3000);
          } else {
            throw new Error('Failed to get auth token');
          }
      } else {
        /**
         *  Redirect to checking page if everything is good. At this point,
         *  we are going to wait for the backend to finish processing
         *  so we can retrieve the updated player information from Descope.
         */
         navigate(`/tch/checking?email=${encodeURIComponent(email)}`);
      }
    } else {
     /**
      * New TCH Process
      * If no match found, redirects to a screen showing options on how the player proceeds with verification
      */
      navigate("/tch/verifyidentity", {
        state: {
          userid: userid,
          email: email,
          phone:phone,
          playerNumber: playerNumber,
          identificationId: idDocnumber,
          idVerified: idVerified
        },
      });

       /**
       *  If no match found, check the player if their ID is verified on Descope. If not, 
       *  process the player and go through the ID verification process. 
       *  (New account for Poker Atlas but not on Descope)
       */
      // navigate("/tch/process", {
      //   state: {
      //     userid: userid,
      //     email: email,
      //     idVerified: idVerified
      //   },
      // });
    }
  } catch (error) {
    console.error("Error fetching user data from Poker Atlas:", error);
    navigate('/tch/error', {
      state: {
        message: 'There was a problem retrieving your information. Proceed to the front desk for assistance.'
      }
    });
  }
};

const handleAPICall = async (guid, loginid) => {
  const baseUrl = `https://auth.${process.env.REACT_APP_DOMAIN}/tch/checking`;
  const params = {
    email: encodeURIComponent(loginid),
  };

  const successurl = buildUrlWithParams(baseUrl, params);
  const errorurl = buildUrlWithParams(baseUrl, params);
  const unverifiedurl = buildUrlWithParams(baseUrl, params);

  let formData = new FormData(); 
  formData.append('GUID', guid); 
  formData.append('loginId', loginid); 
  formData.append('phone', ''); 
  formData.append('verifiedEmail', ''); 
  formData.append('verifiedPhone', '');
  formData.append('successurl', successurl);
  formData.append('errorurl', errorurl);
  formData.append('unverifiedurl', unverifiedurl);

  try {
    const response = await fetch(
      `https://account.${process.env.REACT_APP_DOMAIN}/api/profile/getverificationcodemobiletch`,
      {
        method: "POST",
        headers: {
          Accept: "*/*"
        },
        body: formData,
      }
    );
    const authToken = await response.json();
    return authToken;
  } catch (error) {
    console.error('Fetch error:', error);
    return null;
  }
}

const buildUrlWithParams = (baseUrl, params) => {
  const queryString = new URLSearchParams(params).toString();
  return `${baseUrl}?${queryString}`;
};

const CustomLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const urlFlow = urlParams.get("flow") || DEFAULT_FLOW;

  const [loading, setLoading] = useState(true);
  const [flowId, setFlowId] = useState("");

  useEffect(() => {
    const storedFlow = localStorage.getItem("descope_flow");
    const finalFlow = urlFlow || storedFlow || DEFAULT_FLOW;

    setFlowId(finalFlow);

    if (finalFlow) {
      localStorage.setItem("descope_flow", finalFlow);
    }
  }, [urlFlow]);

  return (
    <>
      <div className="page">
        <Container maxWidth="sm" sx={{ paddingY: "16px" }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={9}>
              <div className="descope-form" data-testid="descope-component">
                {!flowId ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "2rem",
                    }}
                  >
                    <CircularProgress
                      sx={{
                        color: (theme) => theme.palette.grey[50],
                      }}
                      thickness={3}
                      size={60}
                    />
                  </Box>
                ) : (
                  <>
                    <Descope
                      flowId={flowId}
                      onReady={() => {
                        setLoading(false);
                      }}
                      onSuccess={onSuccessHandler(navigate)}
                      onError={(e) => {
                        console.log("Could not log in");
                        console.log(e);
                      }}
                    />

                    {loading && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "2rem",
                        }}
                      >
                        <CircularProgress
                          sx={{
                            color: (theme) => theme.palette.grey[50],
                          }}
                          thickness={3}
                          size={60}
                        />
                      </Box>
                    )}
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default CustomLogin;
