import { Descope, SignInFlow } from '@descope/react-sdk';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DescopeLogin from "../../Descope/DescopeLogin"
import { Container, Grid } from '@mui/material';
import checkAccountStatus from '../../helpers/checkAccountStatus';
import { processAuthToken } from '../../helpers/IdenfyHelper'; 

export default function LoginMobile() {
  const navigate = useNavigate();

  const handleSuccess = async (event) => {
    const details = event.detail;
    const { sessionJwt, refreshJwt } = details;
    const userid = event.detail.user.userId;
    const email = event.detail.user.email;
    const login_type = 'loginmobile'

    const tokenCookie = JSON.stringify({
      access_token: sessionJwt,
      id_token: sessionJwt,
      refresh_token: refreshJwt,
    });

    const statusResult = await checkAccountStatus(email);
      if (statusResult.status === 'ok') {
        const encodedTokenCookie = createEncodedTokenCookie(tokenCookie);
        console.log(encodedTokenCookie);
        window.location.href = `hijackpoker://account?refresh_token=${refreshJwt}`;
        return;
      }

      if(statusResult.status === 'unverified')
      {
        await processAuthToken(userid, email,login_type);
        return;
      }
    
      if(statusResult.status === 'duplicate')
      {
          navigate(`/contact-support?type=${login_type}`, {
            state: {
              message: statusResult.message
            }
          });
    
          return;
      }

    const encodedTokenCookie = createEncodedTokenCookie(tokenCookie);
    console.log(encodedTokenCookie);
    window.location.href = `hijackpoker://account?refresh_token=${refreshJwt}`;
  };

  return (
    <>
      <div className='page'>
        <Container maxWidth="sm">
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={9}>
              <div className='descope-form' data-testid="descope-component">
                <DescopeLogin onSuccess={handleSuccess} onError={(e) => console.log('Could not log in')} />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

function createEncodedTokenCookie(stringToken) {
  let cleanedString = stringToken.replace(/ /g, "%20")
                                .replace(/"/g, "%22")
                                .replace(/,/g, "%2C");
  return cleanedString;
}
