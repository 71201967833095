import React, { useEffect, useState } from 'react';
import tchLogo from '../assets/tch-logo-v.png';
import { Alert, AlertTitle, Container, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';

const DuplicateAccountsPage = () => {
  const location = useLocation();
  const { match, matchValue } = location.state || {};
  const [matchText, setMatchText] = useState('');

  useEffect(() => {
    if (match === 'player_number') {
      setMatchText(`player number: ${matchValue}`);
    } else if (match === 'email') {
      setMatchText(`email address: ${matchValue}`);
    } else if (match === 'phone') {
      setMatchText(`phone number: ${matchValue}`);
    } else {
      setMatchText(`ID document number: ${matchValue}`);
    }
  }, [match, matchValue]);

  return (
    <>
      <div className="page">
        <Container maxWidth="sm" sx={{ paddingY: "16px" }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={9}>
              <div className='descope-form' data-testid="descope-component">
                <div
                  style={{
                    padding: "2rem 2rem",
                    backgroundColor: "#1e1e1e",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  <img style={{ width: '300px', marginBottom: '2rem' }} src={tchLogo} alt="TCH Logo" />
                  <Alert variant="filled" severity="warning" sx={{textAlign: 'left'}}>
                    <AlertTitle>Support Required</AlertTitle>
                    We couldn't finish creating your account profile. Please proceed to the front desk for assistance.
                  </Alert>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default DuplicateAccountsPage;