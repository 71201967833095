import { useNavigate } from 'react-router-dom';
import DescopeLogin from "../../Descope/DescopeLogin"
import { Container, Grid } from '@mui/material';
import checkAccountStatus from '../../helpers/checkAccountStatus';
import { processAuthToken } from '../../helpers/IdenfyHelper'; 

export default function LoginWebGl() {
  const navigate = useNavigate();

  const handleSuccess = async (event) => {
    const details = event.detail;
    const domain = process.env.REACT_APP_DOMAIN;
    const webglCallbackUri  = process.env.REACT_APP_WEBGL_CALLBACK_URI;
    const email = event.detail.user.email;
    const userid = event.detail.user.userId;
    const login_type = 'loginwebgl'

    const { sessionJwt, refreshJwt } = details;

    const tokenCookie = JSON.stringify({
      access_token: sessionJwt,
      id_token: sessionJwt,
      refresh_token: refreshJwt,
    });

    const statusResult = await checkAccountStatus(email);
    if (statusResult.status === 'ok') {

      const encodedTokenCookie = createEncodedTokenCookie(tokenCookie);
      document.cookie = `token-storage=${encodedTokenCookie}; path=/; domain=${domain};`;
      window.location.href = webglCallbackUri;
      return;
    }
    if(statusResult.status === 'unverified')
    {
      await processAuthToken(userid, email, login_type);
      return;
    }
  
    if(statusResult.status === 'duplicate')
    {
        navigate(`/contact-support?type=${login_type}`, {
          state: {
            message: statusResult.message
          }
        });
  
        return;
    }
  };

  return (
    <>
      <div className='page'>
        <Container maxWidth="sm">
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={9}>
              <div className='descope-form' data-testid="descope-component">
                <DescopeLogin onSuccess={handleSuccess} onError={(e) => console.log('Could not log in')} />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

function createEncodedTokenCookie(stringToken) {
  let cleanedString = stringToken.replace(/ /g, "%20")
                                .replace(/"/g, "%22")
                                .replace(/,/g, "%2C");
  return cleanedString;
}
