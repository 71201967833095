import { Descope } from '@descope/react-sdk';

export default function DescopeLogin({ onSuccess, onError }) {
  return (
    <Descope
      flowId="sign-up-or-in"
      onSuccess={onSuccess}
      onError={onError}
      theme='light'
    />
  );
}
