import React, { useEffect, useState } from 'react';
import tchLogo from '../assets/tch-logo-v.png';
import { Container, Grid , Button, Box, CircularProgress, TextField, Typography} from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const NamePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let {
    userid,
    email,
    phone,
    identificationId
  } = location.state || {};


  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newErrors = {};
    if (!firstName.trim()) {
      newErrors.firstName = "First name is required";
    }
    if (!lastName.trim()) {
      newErrors.lastName = "Last name is required";
    }

    if (Object.keys(newErrors).length != 0) {
      setErrors(newErrors);
      return;
    } 

    const params = {
      id: userid,
      email: email,
      phone: phone,
      identificationId: identificationId,
      firstName: firstName,
      lastName: lastName
    };

    setLoading(true);

    try {
      const params = {
        id: userid,
        email: email,
        phone: phone,
        firstName: firstName,
        lastName: lastName
      };

      // Sends First Name and Last Name to Datasync to update both PokerAtlas and Descope
      const response = await axios.post(
        `${process.env.REACT_APP_DATASYNC_URL}/pokeratlas/contact/push/update/name`,
        params
      );

      setLoading(false);

      if (response.data.atlas && (response.data.descope && response.data.descope.ok)){
        navigate('/tch/incomplete', {
          state: {
            firstName: firstName,
            lastName: lastName,
            email: email,
            playerNumber: response.data.atlas?.playerNumber,
            guid: response.data.descope?.data?.userId
          }
        });
      }else{
        console.error("Error fetching user data from Poker Atlas:");
       
        navigate('/tch/error', {
          state: {
            message: 'There was a problem retrieving your information. Proceed to the front desk for assistance.'
          }
       });
      }
    } catch (error) {
      console.error('Fetch error:', error);
      navigate('/tch/error', {
        state: {
          message: 'There was a problem retrieving your information. Proceed to the front desk for assistance.'
        }
      });
    }
  };

  return (
    <>
      <div className="page">
        <Container maxWidth="sm" sx={{ paddingY: "16px" }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={9}>
              <div className='descope-form' data-testid="descope-component">
                <div
                  style={{
                    padding: "2rem 2rem",
                    backgroundColor: "#1e1e1e",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                > 
                  <img style={{ width: '300px', marginBottom: '2rem' }} src={tchLogo} alt="TCH Logo" />

                  <Box sx={{ display: 'flex', 'justifyContent': 'center' }}>
                    {isLoading ? (
                      <CircularProgress 
                        sx={{
                          color: (theme) => theme.palette.grey[50],
                        }}
                        thickness={3}
                        size={60}
                      />
                    ) : 
                        <div style={{ width: '100%', textAlign : 'left'}}>
                          <form>
                          <div>
                          <Typography variant="body2" color="textSecondary" style={{ marginTop: "8px", color: 'white' , fontSize: '14px'}}>
                          Almost there! Just a few more steps! <br/><br/>
                          Please enter your first and last name to continue setting up your account. You’ll still need to visit our location to complete the identity verification process at the front desk.
                          </Typography><br/>
                           
                          <Typography variant="body2" color="textSecondary" style={{ marginTop: "8px", color: 'white' , fontSize: '14px'}}>
                          First Name
                          </Typography>
                            <TextField 
                              required fullWidth value={firstName} size='small'
                              onChange={(e) => setFirstName(e.target.value)}
                              sx={{ backgroundColor: "white", borderRadius: "5px", marginTop: '5px' }}
                              error={!!errors.firstName}
                              helperText={errors.firstName}
                            />
                          <br/><br/>
                          <Typography variant="body2" color="textSecondary" style={{ marginTop: "8px", color: 'white' , fontSize: '14px'}}>
                          Last Name
                          </Typography>
                            <TextField
                              required fullWidth value={lastName} size='small'
                              onChange={(e) => setLastName(e.target.value)}
                              sx={{ backgroundColor: "white", borderRadius: "5px", marginTop: '5px' }}
                              error={!!errors.lastName}
                              helperText={errors.lastName}
                            />      
                          </div>
                           <br/><br/>
                          <Button variant="contained" sx={{ backgroundColor: '#e98c26', color: 'white', width: '100%', "&:hover": {backgroundColor: '#94540f' } }} size="medium" onClick={handleSubmit}>Continue</Button>
                         
                          </form>
                        </div>
                    }
                </Box> 
                  
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default NamePage;