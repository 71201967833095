import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function Callback() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const authorizationCode = params.get('code');

    if (authorizationCode) {
      console.log('Authorization Code:', authorizationCode);
      navigate('/');
    } else {
      console.error('Authorization code not found');
      navigate('/loginwebgl');
    }
  }, [location, navigate]);

  return <div>Loading...</div>;
}
