import React, { useEffect, useState } from 'react';
import hjLogo from '../../assets/hj-logo-h.png';
import { Alert, AlertTitle, Box, Button, Container, createTheme, Grid, Link, ThemeProvider, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import queryString from 'query-string'; 

const VerificationComplete = () => {
  const location = useLocation();
  const { type } = queryString.parse(location.search);

  const getBackToLoginHref = () => {
    const routes = {
      loginmobile: '/loginmobile',
      loginwebgl: '/loginwebgl'
    };
    
    return routes[type] || '/login';
  };

  return (
    <>
      <div className="page">
        <Container maxWidth="sm" sx={{ paddingY: "16px" }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={8} md={9}>
              <div className='descope-form' data-testid="descope-component">
                <div
                  style={{
                    padding: "2rem 2rem",
                    backgroundColor: "#1e1e1e",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  <img style={{ width: '300px', marginBottom: '2rem' }} src={hjLogo} alt="Hijack Logo" />
                  <Typography sx={{marginBottom: '.5rem'}}><CheckCircleOutlineRoundedIcon sx={{fontSize: '6rem', color: 'green'}}/></Typography>
                  <Typography variant='body2' align='center' sx={{marginBottom: '0.5rem'}}>
                    Thank you for completing the ID verification!
                  </Typography>
                  <Typography variant='body2' align='center' sx={{ color: 'gray', marginBottom: '1.5rem' }}>
                    Please log in again to check your account status and continue enjoying your best poker experience.
                  </Typography>
                  <Box sx={{ display: 'flex', marginBottom: '0.5rem' }}>
                    <ThemeProvider theme={DefaultBtn}>
                      <Button
                        href={getBackToLoginHref()} 
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                      >
                        Log in
                      </Button>
                    </ThemeProvider>
                  </Box>
                  <ThemeProvider theme={DefaultBtn}>
                    <Button
                      href="https://help.hijackpoker.com/"
                      variant="outlined"
                      sx={{ textTransform: 'none' }}
                    >
                      Hijack Help Center
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

const DefaultBtn = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#D08E34',
          '&.active': {
            backgroundColor: '#D08E34',
            '&:hover': {
              backgroundColor: '#BE7E27'
            }
          },
          '&:hover': {
            color: 'white',
            backgroundColor: '#BE7E27'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.75rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        },
        outlined: {
          color: '#D08E34',
          borderColor: '#D08E34',
          backgroundColor: '#1e1e1e',
          '&:hover': {
            color: '#BE7E27', 
            borderColor: '#BE7E27',
            backgroundColor: '#161616'
          },
          '&:disabled': {
            color: '#D08E34',
            opacity: '0.4',
            borderColor: '#D08E34'
          }
        }
      }
    }
  }
});

export default VerificationComplete;
