import React, { useEffect, useState } from 'react';
import tchLogo from '../assets/tch-logo-v.png';
import { Alert, AlertTitle, Container, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';

const ErrorPage = () => {
  const location = useLocation();
  const {
    message
  } = location.state || {};
  const [errorMessage, setErrorMessage] = useState('There was an issue with your registration, proceed to the front desk for assistance.');
  
  useEffect(() => {
    if(message) {
      setErrorMessage(message);
    }
  }, [message]);

  return (
    <>
      <div className="page">
        <Container maxWidth="sm" sx={{ paddingY: "16px" }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={9}>
              <div className='descope-form' data-testid="descope-component">
                <div
                  style={{
                    padding: "2rem 2rem",
                    backgroundColor: "#1e1e1e",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  <img style={{ width: '300px', marginBottom: '2rem' }} src={tchLogo} alt="TCH Logo" />
                  <Alert variant="filled" severity="error" sx={{textAlign: 'left'}}>
                    <AlertTitle>Registration Problem</AlertTitle>
                    {errorMessage} <br />
                  </Alert>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default ErrorPage;