import axios from 'axios';


const handleAPICall = async (guid, loginid, login_type) => {
    const baseUrl = `https://auth.${process.env.REACT_APP_DOMAIN}/verification-complete?type=${login_type}`;
    const errorOrNotVerified = `https://auth.${process.env.REACT_APP_DOMAIN}/${login_type}`;
    const params = {
      email: encodeURIComponent(loginid),
    };
  
    const successurl = buildUrlWithParams(baseUrl, params);
    const errorurl = buildUrlWithParams(errorOrNotVerified, params);
    const unverifiedurl = buildUrlWithParams(errorOrNotVerified, params);
  
    let formData = new FormData(); 
    formData.append('GUID', guid); 
    formData.append('loginId', loginid); 
    formData.append('phone', ''); 
    formData.append('verifiedEmail', ''); 
    formData.append('verifiedPhone', '');
    formData.append('successurl', successurl);
    formData.append('errorurl', errorurl);
    formData.append('unverifiedurl', unverifiedurl);
  
    try {
      const response = await fetch(
        `https://account.${process.env.REACT_APP_DOMAIN}/api/profile/getverificationcodemobiletch`,
        {
          method: "POST",
          headers: {
            Accept: "*/*"
          },
          body: formData,
        }
      );
      const authToken = await response.json();
      return authToken;
    } catch (error) {
      console.error('Fetch error:', error);
      return null;
    }
  }
  
  const buildUrlWithParams = (baseUrl, params) => {
    const queryString = new URLSearchParams(params).toString();
    return `${baseUrl}?${queryString}`;
  };
  

const redirectToAuth = (authToken) => {
  if (authToken && typeof authToken === 'string') {
    setTimeout(() => {
      window.location.replace(`https://ivs.idenfy.com/api/v2/redirect?authToken=${authToken}`);
    }, 3000);
  } else {
    throw new Error('Failed to get auth token');
  }
};

const processAuthToken = async (userid, email, login_type) => {
  try {
    const authToken = await handleAPICall(userid, email,login_type);
    redirectToAuth(authToken);
  } catch (error) {
    console.error(error.message);
  }
};

export { handleAPICall, redirectToAuth, processAuthToken };
