import React from 'react';
import tchLogo from '../assets/tch-logo-v.png';
import { Link } from 'react-router-dom';
import { Container, Typography, Box, Button, Grid } from '@mui/material';

const NotFoundPage = () => {
  return (
    <>
      <div className="page">
        <Container maxWidth="sm" sx={{ paddingY: "16px" }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={9}>
              <div className="descope-form" data-testid="descope-component">
                <div
                  style={{
                    padding: "2rem 2rem",
                    backgroundColor: "#1e1e1e",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{ width: "300px", marginBottom: "1rem" }}
                    src={tchLogo}
                    alt="TCH Logo"
                  />
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h2" component="h2">
                      404
                    </Typography>
                    <Typography variant="h5" component="h5">
                      Page Not Found
                    </Typography>
                    <Typography variant="body1" mt={3} mb={3}>
                      Sorry, the page you are looking for does not exist.
                    </Typography>
                  </Box>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default NotFoundPage;