import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from '@descope/react-sdk';
// require('dotenv').config()

const projectRegex = /^P[a-zA-Z0-9]{27}$/;
const DEFAULT_PROJECT_ID = '';
const DEFAULT_FLOW = 'sign-up-or-in';

const Index = () => {
  const [projectId, setProjectId] = useState('');
  const [baseUrl] = useState('https://dsauth.hijack.poker');
  const [flow, setFlow] = useState(DEFAULT_FLOW);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlFlow = urlParams.get('flow');
    const pathnameProjectId = projectRegex.exec(
      window.location.pathname?.split('/').at(-1) || ''
    )?.[0];

    const envProjectId = projectRegex.exec(
      process.env.REACT_APP_DESCOPE_PROJECT_ID ?? ''
    )?.[0];

    const finalProjectId = pathnameProjectId ?? envProjectId ?? localStorage.getItem('descope_project_id') ?? DEFAULT_PROJECT_ID;
    const finalFlow = urlFlow ?? localStorage.getItem('descope_flow') ?? DEFAULT_FLOW;

    setProjectId(finalProjectId);
    setFlow(finalFlow);

    if (window.location.pathname.includes("/login") || window.location.pathname.includes("/tch/login")) {
      localStorage.setItem('descope_project_id', finalProjectId);
      localStorage.setItem('descope_flow', finalFlow);
    }

    setIsReady(true);
  }, []);

  if (!isReady) {
    return <div>Loading...</div>;
  }

  return (
    <React.StrictMode>
      <AuthProvider projectId={projectId} baseUrl={baseUrl}>
        <App />
      </AuthProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
