import axios from 'axios';

const checkAccountStatus = async (email) => {
  if (!email) {
    return {
      status: "error",
      message: 'We could not find your account using your email address. Contact support for assistance.'
    };
  }

  try {
    const params = { email };
    const response = await axios.post(`${process.env.REACT_APP_DATASYNC_URL}/descope/search`, params);
    const data = response.data;

    if (data) {
      const { duplicateDocumentNum, idVerified } = data.customAttributes;

      if (duplicateDocumentNum) {
        return {
          status: "duplicate",
          message: 'It seems a duplicate account has already been registered with this information. If this is an error or you need help accessing your account, please contact support.'
        };
      }

      if (idVerified === 'APPROVED' || idVerified === 'SUSPECTED') {
        return {
          status: "ok",
          message: 'ID Verified successfully.'
        };
      } else {
        return {
          status: "unverified",
          message: 'Your account is not yet verified. Please complete the verification process to continue. If you need assistance, contact support.'
        };
      }
    } else {
      return {
        status: "error",
        message: 'We could not find any account matching the provided information. Please double-check your details or contact support for assistance.'
      };
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    return {
      status: "error",
      message: 'We encountered a problem retrieving your information. Please try again later or contact support if the issue persists.'
    };
  }
};

export default checkAccountStatus;
