import React from 'react';
import tchLogo from '../assets/tch-logo-v.png';
import { Button, Container, Grid, ThemeProvider, Typography, createTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const EmailNotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="page">
        <Container maxWidth="sm" sx={{ paddingY: "16px" }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={9}>
              <div className='descope-form' data-testid="descope-component">
                <div
                  style={{
                    padding: "2rem 2rem",
                    backgroundColor: "#1e1e1e",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  <img style={{ width: '300px', marginBottom: '1rem' }} src={tchLogo} alt="TCH Logo" />
                  <Typography mb={4} mt={2}>
                    We could not retrieve your account information. Proceed to the front desk for assistance or try signing up again with a different email address.
                  </Typography>
                  <ThemeProvider theme={DefaultBtn}>
                    <Button size='small' sx={{marginBottom: '1rem'}} onClick={() => navigate(`/tch/login/${process.env.REACT_APP_DESCOPE_PROJECT_ID}?flow=tchregistration`)}>
                      Sign up
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

const DefaultBtn = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#D08E34',
          '&.active': {
            backgroundColor: '#D08E34',
            '&:hover': {
              backgroundColor: '#BE7E27'
            }
          },
          '&:hover': {
            color: 'white',
            backgroundColor: '#BE7E27'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.75rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});

export default EmailNotFound;