export const updateFavicon = (faviconURL) => {
  const favicon = document.getElementById('favicon');
  if (favicon) {
    favicon.href = faviconURL;
  } else {
    const link = document.createElement('link');
    link.id = 'favicon';
    link.rel = 'icon';
    link.href = faviconURL;
    document.head.appendChild(link);
  }
};