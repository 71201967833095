import React, { useEffect, useState } from 'react';
import hjLogo from '../../assets/hj-logo-h.png';
import { Alert, AlertTitle, Box, Button, Container, createTheme, Grid, Link, ThemeProvider, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import queryString from 'query-string'; 

const ContactSupport = () => {
  const location = useLocation();
  const { type } = queryString.parse(location.search);
  const {
    message
  } = location.state || {};
  const [errorMessage, setErrorMessage] = useState('There was an issue with your account, contact support for assistance.');
  
  useEffect(() => {
    if(message) {
      setErrorMessage(message);
    }
  }, [message]);

  const getBackToLoginHref = () => {
    const routes = {
      loginmobile: '/loginmobile',
      loginwebgl: '/loginwebgl'
    };
    
    return routes[type] || '/login';
  };
  

  return (
    <>
      <div className="page">
        <Container maxWidth="sm" sx={{ paddingY: "16px" }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={8} md={9}>
              <div className='descope-form' data-testid="descope-component">
                <div
                  style={{
                    padding: "2rem 2rem",
                    backgroundColor: "#1e1e1e",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  <img style={{ width: '300px', marginBottom: '2rem' }} src={hjLogo} alt="Hijack Logo" />
                  <Alert severity="error" sx={{textAlign: 'left', marginBottom: '1.5rem'}}>
                    <AlertTitle>Account Issue</AlertTitle>
                    {errorMessage} <br />
                  </Alert>
                  <Typography variant='body2' align='left' sx={{marginBottom: '0.5rem'}}>
                    Let us know about the issue, and we will get back to you promptly.
                  </Typography>
                  <Typography variant='body2' align='left' sx={{ color: 'gray', marginBottom: '1.5rem' }}>
                    Our support team is available to help with any acount issues or general inquiries. Please include your <Typography variant='body2' component='span' sx={{textDecoration: 'underline'}}>email address</Typography>, <Typography variant='body2' component='span' sx={{textDecoration: 'underline'}}>phone number</Typography>, and <Typography variant='body2' component='span' sx={{textDecoration: 'underline'}}>driver's license number</Typography> in the email for faster assistance.
                  </Typography>
                  <Box sx={{ display: 'flex', marginBottom: '0.5rem' }}>
                    <ThemeProvider theme={DefaultBtn}>
                      <Button
                        href="mailto:support@hijackpoker.com"
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                      >
                        <EmailOutlinedIcon sx={{ marginRight: '0.5rem' }} /> Email support
                      </Button>
                    </ThemeProvider>
                  </Box>
                  <ThemeProvider theme={DefaultBtn}>
                    <Button
                      href={getBackToLoginHref()} 
                      variant="outlined"
                      sx={{ textTransform: 'none' }}
                    >
                      Back to login
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

const DefaultBtn = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#D08E34',
          '&.active': {
            backgroundColor: '#D08E34',
            '&:hover': {
              backgroundColor: '#BE7E27'
            }
          },
          '&:hover': {
            color: 'white',
            backgroundColor: '#BE7E27'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.75rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        },
        outlined: {
          color: '#D08E34',
          borderColor: '#D08E34',
          backgroundColor: '#1e1e1e',
          '&:hover': {
            color: '#BE7E27', 
            borderColor: '#BE7E27',
            backgroundColor: '#161616'
          },
          '&:disabled': {
            color: '#D08E34',
            opacity: '0.4',
            borderColor: '#D08E34'
          }
        }
      }
    }
  }
});

export default ContactSupport;
